/**
 * Actions Types
 */

export enum ConsultMarginQueryTypes {
  SET_INFO_INSS_DADOS_BANCARIOS = '@portabilidade/consultMarginQuery/SET_INFO_INSS_DADOS_BANCARIOS',
  REQUEST_MARGIN_ACCEPT = '@portabilidade/consultMarginQuery/REQUEST_MARGIN_ACCEPT',
  SET_LOADING_QUERY = '@portabilidade/consultMarginQuery/SET_LOADING_QUERY',
  SET_COUNTER_RECALL = '@portabilidade/consultMarginQuery/SET_COUNTER_RECALL',
  REQUEST_MARGIN_RESULT = '@portabilidade/consultMarginQuery/REQUEST_MARGIN_RESULT',
  MARGIN_SUCCESS = '@portabilidade/consultMarginQuery/MARGIN_SUCCESS',

  RESET = 'RESET',
}

export interface InfoInssType {
  banco: string
  agencia: string
  conta: string
  digitoConta: string
  tipoCredito: string
  margem: number
  nome: string
}

/**
 * State Type
 */

export interface ConsultaMarginQueryState {
  readonly dadosBancarios: InfoInssType
  readonly loadingQuery: boolean
  readonly counterRecall: number
  readonly code: string
  readonly marginSuccess: boolean
}

export type StartPayload = {
  convenioDescricao: string
  salario?: number | undefined
  callback?: () => void
  callbackError?: () => void
}

export type ResultPayload = {
  convenioDescricao: string
  callback: (code: string) => void
}
