import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { openLinkByEnviroment } from '@utils/functions'

import { Container, Header, ListItem, ListStructure, NumberCount } from './styles'
import { listaAutorizacaoPortal } from '../types'

interface OwnProps {
  setShowBottomsheetSiape: React.Dispatch<React.SetStateAction<boolean>>
}

const BottomsheetAutorizar = ({ setShowBottomsheetSiape }: OwnProps) => (
  <BottomSheet onClose={() => setShowBottomsheetSiape(false)} noSwipe>
    <Container>
      <Header>
        <Text variant="body-2" bold>
          Saiba como autorizar a consulta de margem
        </Text>
        <Button variant="link" onClick={() => setShowBottomsheetSiape(false)}>
          Fechar
        </Button>
      </Header>
      <ListStructure>
        {listaAutorizacaoPortal?.map(({ code, description }) => (
          <ListItem key={code}>
            <NumberCount>
              <p>{code}</p>
            </NumberCount>
            <Text variant="body-3">{description}</Text>
          </ListItem>
        ))}
      </ListStructure>
    </Container>
    <Button
      fullWidth
      onClick={async () => {
        await openLinkByEnviroment('https://www.gov.br/sougov', true)
      }}
    >
      Acessar SouGov
    </Button>
  </BottomSheet>
)

export default BottomsheetAutorizar
