import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { clearError } from '@store/ui/error/actions'
import { BridgeService } from '@services/bridge'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { validaEnvHomeByBridge } from '@utils/functions'
import { ApplicationState } from '@store/types'
import Tags from '@utils/Tags'

import { connector, Props } from './props'
import { Header } from './styles'

const ErrorMessage = ({
  showError = false,
  actionCallback,
  block,
  dispatch,
  functionCallback,
}: Props) => {
  const [open, setOpen] = useState(showError)
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { nomeConvenioSelecionado } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (open) {
      Tags.enviarTagueamentoApp({
        ref_figma: '7',
        name: 'CONSIGNADO_PORT_FLUXO',
        screen: `Erro dialog`,
        ref_type: 'Feedback',
        content_action: 'Dado carregado',
        action_id: `Estamos resolvendo um erro`,
        product: nomeConvenioSelecionado
          ? `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`
          : 'PORTABILIDADE_CONSIGNADO',
      })
      BridgeService.tac({
        name: 'error-dialog',
        actions: [
          'continuar_portabilidade',
          'selecionar_convenio',
          'autorizar_termo_inss',
          'autorizar_termo_siape',
          'buscar_documentos',
          'solicitar_biometria',
          'confirmar_contratacao',
          'ver_termos',
        ],
        isError: true,
      })
    }
  }, [nomeConvenioSelecionado, open])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  if (open) {
    return (
      <BottomSheet
        onClose={
          !block
            ? () => {
                setOpen(false)
                setTimeout(() => {
                  dispatch(clearError())
                }, 600)
              }
            : () => null
        }
      >
        <Header>
          <Text variant="headline-h3" color="typography" semiBold>
            Estamos resolvendo um erro
          </Text>
        </Header>
        <Text variant="body-3">
          Mas fique tranquilo, em breve você poderá continuar o seu processo.
        </Text>

        <Button
          fullWidth
          style={{ marginTop: '16px' }}
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '8',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: `Erro dialog`,
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: `Tentar novamente`,
              product: nomeConvenioSelecionado
                ? `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`
                : 'PORTABILIDADE_CONSIGNADO',
            })
            setOpen(false)
            setTimeout(() => {
              dispatch(clearError())
              if (actionCallback instanceof Function) {
                dispatch(actionCallback())
              } else if (functionCallback instanceof Function) {
                functionCallback()
              }
            }, 600)
          }}
        >
          Tentar novamente
        </Button>
        <Button
          fullWidth
          variant="secondary"
          style={{ marginTop: '16px' }}
          onClick={() => {
            Tags.enviarTagueamentoApp({
              ref_figma: '8',
              name: 'CONSIGNADO_PORT_FLUXO',
              screen: `Erro dialog`,
              ref_type: 'Botao',
              content_action: 'Toque',
              action_id: `Voltar para home`,
              product: nomeConvenioSelecionado
                ? `PORTABILIDADE_CONSIGNADO_${nomeConvenioSelecionado?.toUpperCase()}`
                : 'PORTABILIDADE_CONSIGNADO',
            })
            setOpen(false)
            window.location.href = validaEnvHomeByBridge(isApp, String(origemGoBack)) || ''
          }}
        >
          Voltar para home
        </Button>
      </BottomSheet>
    )
  }
  return <></>
}

export default connector(ErrorMessage)
