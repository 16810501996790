import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { openLinkByEnviroment } from '@utils/functions'

import { Container, ContainerButton, Header, ListItem, ListStructure, NumberCount } from './styles'
import { listaAutorizacaoPortal } from '../types'

interface OwnProps {
  setShowBottomsheet: React.Dispatch<React.SetStateAction<boolean>>
  consultarMargem: () => void
}

const BottomsheetPrimeiraTentativa = ({ setShowBottomsheet, consultarMargem }: OwnProps) => (
  <BottomSheet onClose={() => setShowBottomsheet(false)} noSwipe>
    <Container>
      <Header>
        <Text variant="body-2" bold colorWeight={500}>
          Não foi possível consultar a margem
        </Text>
        <Text variant="body-3">
          Pra simular seu empréstimo, precisamos que você autorize a consulta de margem.
        </Text>
      </Header>
      <ListStructure>
        {listaAutorizacaoPortal?.map(({ code, description }) => (
          <ListItem key={code}>
            <NumberCount>
              <p>{code}</p>
            </NumberCount>
            <Text variant="body-3">{description}</Text>
          </ListItem>
        ))}
      </ListStructure>
    </Container>
    <ContainerButton>
      <Button
        fullWidth
        onClick={async () => {
          await openLinkByEnviroment('https://www.gov.br/sougov', true)
        }}
      >
        Acessar SouGov
      </Button>
      <Button
        fullWidth
        variant="secondary"
        onClick={() => {
          setShowBottomsheet(false)
          consultarMargem()
        }}
      >
        Já autorizei
      </Button>
    </ContainerButton>
  </BottomSheet>
)

export default BottomsheetPrimeiraTentativa
