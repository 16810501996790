import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

const getValidaToken = (data: ValidaCodigoApiRequest, captcha: string) => {
  const pathname = !BridgeService.isBrowser()
    ? `${process.env.REACT_APP_ENVIA_CODIGO}?codigo=${data.codigo}&id=${data.id}&origem=${data.origem}`
    : process.env.REACT_APP_ENVIA_CODIGO_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  let headers = {}
  if (!BridgeService.isBrowser()) {
    headers = {
      captcha,
    }
  } else {
    headers = {
      captcha,
      code: data.codigo,
      id: data.id,
      origin: data.origem,
    }
  }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface ValidaCodigoApiRequest {
  codigo: string
  id: string
  origem: string
}

export default getValidaToken
