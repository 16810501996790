import { addMonths, addDays, differenceInYears, format } from 'date-fns'

import { Banco, Cidade, Convenio, Estado, Orgao, Upag } from '@store/globalApi/types'
import {
  BancosCodes,
  ConveniosSituacaoFuncional,
  FontesPagamentoTitle,
  ForcasArmadasConvenios,
  MotivosInss,
  SituacoesFuncionaisSalesforce,
} from '@utils/enums'
import caixaLogo from '@images/banksIcons/logoCaixa.png'
import itauLogo from '@images/banksIcons/logoItau.png'
import bbLogo from '@images/banksIcons/logoBB.png'
import bradescoLogo from '@images/banksIcons/logoBradesco.png'
import santanderLogo from '@images/banksIcons/logoSantander.png'
import { BridgeService } from '@services/bridge'
import { ContractsRequestType } from '@store/portabilidade/simulacaoCompleta/types'
import { ContratosTypes } from '@store/portabilidade/contratoAtual/types'
import { currencyToFloat } from '@utils/masks'

/**
 * Converte timestamp para formato de data PT
 *
 * @param timestamp Numero a ser convertido
 * @returns Data formatada
 */
export const timestampToDatePt = (timestamp: number) => {
  if (!timestamp) return null
  const getDate =
    new Date(timestamp).getUTCDate() < 10
      ? `0${new Date(timestamp).getUTCDate()}`
      : new Date(timestamp).getUTCDate()
  const getMonth =
    new Date(timestamp).getMonth() + 1 < 10
      ? `0${new Date(timestamp).getMonth() + 1}`
      : new Date(timestamp).getMonth() + 1
  const getYear = new Date(timestamp).getFullYear()
  return `${getDate}/${getMonth}/${getYear}`
}

/**
 * Converte data formato de pattern passado por parametro
 *
 * @param date Data a ser convertida
 * @param pattern String do formato de data desejado
 * @returns Data formatada
 */
export const DateFormat = (date: Date, pattern = 'YYYY-MM-DDTHH:mm:ss.SSSZZ') =>
  format(date, pattern)

/**
 * Busca data atual
 *
 * @returns Data atual
 */
export const CurrentDate = () => DateFormat(new Date())

/**
 * Busca idade
 *
 * @param date Data a ser convertida
 * @param currentDate Data corrente
 * @param hasSumDate Boolean de somatorio de data
 * @returns Idade
 */
export const describeAge = (date: Date, currentDate = new Date(), hasSumDate = false) => {
  const now = currentDate
  let birthday = addMonths(date, 1)
  birthday = addDays(birthday, 1)
  let years = differenceInYears(now, birthday)

  if (
    date.getMonth() + 1 === currentDate.getMonth() + 1 &&
    date.getDate() > currentDate.getDate() &&
    hasSumDate
  ) {
    years += 1
  }
  return years
}

/**
 * Valida para onde será encaminhado a home
 *
 * @param isApp Boolean se está utilizando pelo aplicativo
 * @param isConsignado Boolean se é credito consignado
 * @returns Caminho a ser direcionado
 */
export const validaEnvHomeByBridge = (
  isApp: boolean,
  origemGoBack: string,
  isConsignado?: boolean,
) => {
  if (isApp) {
    if (origemGoBack === 'emprestimoinss') return `${process.env.REACT_APP_HOME_EMPRESTIMO}/inss`

    if (origemGoBack === 'emprestimosanguelaranja')
      return `${process.env.REACT_APP_SIMULADOR_CONSIGNADO}/novo?origem=app&familia=publico&origemgoback=emprestimo&sangueLaranja=true`

    return isConsignado
      ? `${process.env.REACT_APP_HOME_EMPRESTIMO}/consignado`
      : process.env.REACT_APP_HOME_EMPRESTIMO
  }
  return process.env.REACT_APP_HOME_SIMULADOR_NOVO
}

/**
 * Remove as acentuações da string.
 *
 * @param data String a ser removida a acentuação.
 * @param lowercase Indicador para informar se deseja transformar em lowercase.
 * @returns String sem acentuação.
 */
export const RemoveAccents = (data: string, lowercase = true) => {
  try {
    if (lowercase) {
      data = data.toLowerCase()
    }

    return data
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  } catch {
    return data
  }
}

/**
 * Controi um formato de banco adequado para dropdown
 *
 * @param bancos Array de codigo e nome dos bancos recebido através de api
 * @returns String mapeada
 */
export const buildBancos = (bancos: Banco[]) =>
  bancos?.map(({ nome, codigo }) => ({ value: codigo, text: nome }))

/**
 * Controi um formato de convenio adequado para dropdown
 *
 * @param convenios Array de codigo e nome dos convenios recebido através de api
 * @returns String mapeada
 */
export const buildConvenios = (convenios: Convenio[]) =>
  convenios?.map((convenio) => ({
    value: convenio.codigo,
    text: convenio.descricao,
  }))

/**
 * Controi um formato de cidade adequado para dropdown
 *
 * @param convenios Array de codigo e nome das cidades recebido através de api
 * @returns String mapeada
 */
export const buildCidades = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({ value: cidade.codigo, text: cidade.nome }))

/**
 * Controi um formato de estado adequado para dropdown
 *
 * @param convenios Array de codigo e nome dos estados recebido através de api
 * @returns String mapeada
 */
export const buildEstados = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, text: uf }))

/**
 * Controi um formato de upag adequado para dropdown
 *
 * @param convenios Array de codigo e nome das upags recebido através de api
 * @returns String mapeada
 */
export const buildUpags = (upags: Upag[]) =>
  upags?.map((upag) => ({
    value: `${upag.sigla} - ${upag.nome} - ${upag.codigoUpag}`,
    text: `${upag.sigla} - ${upag.nome}`,
  }))

/**
 * Controi um formato de orgao adequado para dropdown
 *
 * @param convenios Array de codigo e nome dos orgaos recebido através de api
 * @returns String mapeada
 */
export const buildOrgaos = (orgaos: Orgao[]) =>
  orgaos?.map((orgao) => ({ value: orgao.codigo, text: orgao.descricao }))

/**
 * Converter data em formato PT para formato EN
 *
 * @param date String a ser convertida
 * @returns Conversão
 */
export const datePtToEn = (date: string) =>
  date
    ? format(
        new Date(
          Number(date.substring(6)),
          Number(date.substring(3, 5)) - 1,
          Number(date.substring(0, 2)),
        ),
        'yyyy-MM-dd',
      )
    : ''

/**
 * Encontrar Upag
 *
 * @param listaUpags Lista de Upags a ser mapeado
 * @param upag String de upag que será pesquisado
 * @returns Sigla e nome caso encontrado ou vazio caso não encontrado
 */
export const findUpag = (listaUpags: Upag[], upag: string) => {
  const upagFound = listaUpags?.find(
    (upagItem) => `${upagItem.sigla} - ${upagItem.nome} - ${upagItem.codigoUpag}` === upag,
  )
  if (upagFound) {
    return `${upagFound?.sigla} - ${upagFound.nome}`
  }
  return ''
}

/**
 * Encontrar Fonte Pagamento
 *
 * @param fontePagamento fonte a ser mapeada
 * @returns nome caso encontrado ou vazio caso não encontrado
 */
export const findFontePagamento = (fontePagamento: number) => {
  const title = FontesPagamentoTitle.find((fonte) => fonte.value === fontePagamento)
  return title?.label
}

/**
 * Encontrar Fonte Pagamento Forcas Armadas
 *
 * @param fontePagamentoForcasArmadas fonte a ser mapeada
 * @returns nome caso encontrado ou vazio caso não encontrado
 */
export const findFontePagamentoForcasArmadas = (fontePagamentoForcasArmadas: number) => {
  const title = ForcasArmadasConvenios.find((fonte) => fonte.value === fontePagamentoForcasArmadas)
  return title?.label
}

/**
 * Encontrar Situacao Funcional de acordo com o convenio
 *
 * @param convenio fonte a ser mapeada
 * @returns value e text caso encontrado ou vazio caso não encontrado
 */
export const findConveniosSituacaoFuncional = (convenio: number) => {
  const situacao = ConveniosSituacaoFuncional.find((fonte) => fonte.value === convenio)
  if (situacao) return situacao.options
  return []
}

/**
 * Busca os motivos de acordo com a situacao funcional do inss
 *
 * @param situacaoFuncional situacao a ser mapeada
 * @returns value e text caso encontrado ou vazio caso não encontrado
 */
export const motivoOptions = (situacaoFuncional: string | number | undefined) => {
  if (!situacaoFuncional) return []
  if (situacaoFuncional === 'Aposentados') {
    return MotivosInss.aposentado
  }
  if (situacaoFuncional === 'Pensionistas') {
    return MotivosInss.pensionista
  }
  return []
}

/**
 * Busca o periodo em que a api do inss funciona
 *
 * @param horaAtual hora em que está sendo pesquisado
 * @returns true caso esteja dentro do periodo
 */
export const getHoraBaseINSS = (horaAtual: string) =>
  Number(horaAtual) >= 6 && Number(horaAtual) <= 21

export type SituacaoFuncionalTypes = 1 | 2 | 3 | 10 | 7 | 8
/**
 * Encontrar Situacao Funcional de acordo com o salesforce
 *
 * @param convenio fonte a ser mapeada
 * @returns value e text caso encontrado ou vazio caso não encontrado
 */
export const findSituacaoFuncionalSalesforce = (
  convenio: SituacaoFuncionalTypes,
  situacaoFuncional: string,
) => {
  const situacoes = {
    1: SituacoesFuncionaisSalesforce.ESTADUAL,
    2: SituacoesFuncionaisSalesforce.MUNICIPAL,
    3: SituacoesFuncionaisSalesforce.INSS,
    10: SituacoesFuncionaisSalesforce.FEDERAL_SIAPE,
    7: SituacoesFuncionaisSalesforce.FORCAS_ARMADAS,
    8: SituacoesFuncionaisSalesforce.TRIBUNAIS,
  }

  if (convenio && situacaoFuncional)
    return situacoes[convenio].find((situacao) => situacao.value === situacaoFuncional)?.text || ''
  return ''
}

export const logoByBank = (banco: number) => {
  switch (String(banco)) {
    case BancosCodes.CAIXA:
      return caixaLogo
    case BancosCodes.ITAU:
      return itauLogo
    case BancosCodes.BB:
      return bbLogo
    case BancosCodes.BRADESCO:
      return bradescoLogo
    case BancosCodes.SANTANDER:
      return santanderLogo
    default:
      return ''
  }
}

export const openLinkByEnviroment = async (url: string, OpenNewTab?: boolean) => {
  if (BridgeService.isBrowser()) {
    if (OpenNewTab) {
      window.open(url, '_blank')?.focus()
    } else {
      window.location.href = url
    }
  } else {
    await BridgeService.openDeepLink(url)
  }
}

export function toTitleCase(input: string): string {
  return input
    .toLowerCase() // Converte todo o texto para minúsculas
    .split(' ') // Divide o texto em palavras
    .map(
      (word) =>
        // Converte a primeira letra de cada palavra para maiúscula e junta com o restante
        word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join(' ') // Junta as palavras resultantes
}

export const TipoCreditoApiInss = [
  {
    value: '1',
    label: 'Cartão Magnético',
  },
  {
    value: '2',
    label: 'Conta Corrente',
  },
]

export const findTipoContaByLabel = (label: string) =>
  TipoCreditoApiInss.find((item) => item.label === label)?.value

export const base64toBlob = (base64: string, contentType = 'image/jpeg', sliceSize = 512) => {
  const byteCharacters = atob(base64.replace(/data:.+?,/, ''))
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const getOrientation = (
  imageFile: File,
  onRotationFound: (rotationInDegrees: number) => void,
) => {
  const reader = new FileReader()
  reader.onload = (event: ProgressEvent) => {
    if (!event.target) {
      return
    }

    const innerFile = event.target as FileReader
    const view = new DataView(innerFile.result as ArrayBuffer)

    if (view.getUint16(0, false) !== 0xffd8) {
      onRotationFound(-2)
    }

    const length = view.byteLength
    let offset = 2

    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) {
        onRotationFound(-1)
      }
      const marker = view.getUint16(offset, false)
      offset += 2

      if (marker === 0xffe1) {
        offset += 2
        if (view.getUint32(offset, false) !== 0x45786966) {
          onRotationFound(-1)
        }

        const little = view.getUint16((offset += 6), false) === 0x4949
        offset += view.getUint32(offset + 4, little)
        const tags = view.getUint16(offset, little)
        offset += 2
        for (let i = 0; i < tags; i += 1) {
          if (view.getUint16(offset + i * 12, little) === 0x0112) {
            onRotationFound(view.getUint16(offset + i * 12 + 8, little))
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }
    onRotationFound(-1)
  }
  reader.readAsArrayBuffer(imageFile)
}

export const rotateImageOrientation = async (
  imageSrc: string,
  orientation: number,
): Promise<string> => {
  const image = new Image()
  image.src = imageSrc

  const canvas = document.createElement('canvas')
  const ctx = canvas?.getContext('2d')

  return new Promise((resolve) => {
    image.addEventListener('load', () => {
      if (orientation > 4 && orientation < 9) {
        canvas.width = image.height
        canvas.height = image.width
      } else {
        canvas.width = image.width
        canvas.height = image.height
      }

      switch (orientation) {
        case 2:
          ctx?.transform(-1, 0, 0, 1, image.width, 0)
          break
        case 3:
          ctx?.transform(-1, 0, 0, -1, image.width, image.height)
          break
        case 4:
          ctx?.transform(1, 0, 0, -1, 0, image.height)
          break
        case 5:
          ctx?.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx?.transform(0, 1, -1, 0, image.height, 0)
          break
        case 7:
          ctx?.transform(0, -1, -1, 0, image.height, image.width)
          break
        case 8:
          ctx?.transform(0, -1, 1, 0, 0, image.width)
          break
        default:
          break
      }
      ctx?.drawImage(image, 0, 0)
      resolve(canvas?.toDataURL('image/jpeg'))
    })
  })
}

export const contractsMapper = (contracts: ContratosTypes[]): ContractsRequestType[] =>
  contracts.map((contrato) => ({
    installmentValue: Number(currencyToFloat(contrato.valorParcela)),
    contractNumber: contrato.numeroContrato,
    debtBalance: Number(currencyToFloat(contrato.saldoDevedor)),
    numberOfRemaingInstallments: Number(contrato.parcelasRestantes),
  }))

export const ConvertDateUTC = (date: string) => {
  const newDate = new Date(date)
  newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000)
  return newDate
}

export const formatDecimalBr = (value: string | number | undefined) =>
  value ? String(value).replace('.', ',') : ''
