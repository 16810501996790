import React from 'react'

import { useSelector } from 'react-redux'

import NewHeader from '@interco/institucional-frontend-lib-header/dist'
import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import { ApplicationState } from '@store/types'

import { HeaderFooterProps } from './types'

const HeaderAndFooter: React.FC<HeaderFooterProps> = ({ children }) => {
  const { isApp, hideHeader, hideFooter } = useSelector(
    (state: ApplicationState) => state.ui.navigation,
  )
  const showHeader = () => !isApp && !hideHeader
  const showFooter = () => !isApp && !hideFooter

  return (
    <>
      {showHeader() && <NewHeader hideBabi />}
      {children}
      {showFooter() && <Footer />}
    </>
  )
}

export default HeaderAndFooter
