import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Text } from '@interco/inter-ui/components/Text'
import NovoPage from '@templates/NovoPage'
import { Colors } from '@utils/colors'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { conveniosRequest, fontesRequest } from '@store/globalApi/actions'
import { ApplicationState } from '@store/types'
import { FontesPagamento } from '@utils/enums'
import { useMobileUserInfo } from '@hooks'
import { Loading } from '@atoms'
import { setFonteSelecionada } from '@store/portabilidade/dadosProfissionais/actions'
import { setIsNewSimulatorApi } from '@store/ui/navigation/actions'
import routes from '@routes/routes'
import { BridgeService } from '@services/bridge'
import { setCpf, setDataNascimento, setNome } from '@store/portabilidade/dadosPessoais/actions'
import { setEmail, setTelefone } from '@store/portabilidade/contato/actions'
import { timestampToDatePt } from '@utils/functions'
import Tags from '@utils/Tags'

import { ColumnText, DivLoading, LineInfo } from './styles'
import { homeConvenios } from './func'
import BottomsheetInss from '../Grupos/Inss/BottomsheetInss'

const SelecaoConvenio = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showBottomSheetInss, setShowBottomSheetInss] = useState(false)
  const { fontesPagamento } = useSelector((state: ApplicationState) => state.globalApi)
  const { fonteSelecionada } = useSelector(
    (state: ApplicationState) => state.portabilidade.dadosProfissionais,
  )
  const loading = useSelector((state: ApplicationState) => state.globalApi.fontesPagamentoLoading)
  const { conveniosLoading } = useSelector((state: ApplicationState) => state.globalApi)

  useEffect(() => {
    BridgeService.tac({
      name: 'selecao_convenio',
      actions: ['continuar_portabilidade'],
    })
  }, [])

  const userInfo = useMobileUserInfo()

  // GET USER INFO
  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      dispatch(setNome(userInfo?.name || ''))
      dispatch(setCpf(userInfo?.cpf || ''))
      dispatch(setTelefone(userInfo?.cellPhoneNumber || ''))
      dispatch(setEmail(userInfo?.email || ''))
      dispatch(setDataNascimento(timestampToDatePt(Number(userInfo?.birthDate)) || ''))
    }
  }, [userInfo, dispatch])

  useEffect(() => {
    if (!fontesPagamento || fontesPagamento.length === 0) {
      dispatch(fontesRequest())
    }
  }, [dispatch, fontesPagamento])

  const navigateNextPage = (codigo: number) => {
    if ([FontesPagamento.INSS, FontesPagamento.SIAPE].includes(codigo)) {
      dispatch(setIsNewSimulatorApi(true))
    } else {
      dispatch(setIsNewSimulatorApi(false))
    }
    dispatch(setFonteSelecionada(codigo))
    dispatch(
      conveniosRequest({
        fontePagamento: Number(codigo),
        callback: () => {
          BridgeService.tic({
            name: 'btn_convenio',
            action: 'selecionar_convenio',
          })
          Tags.enviarTagueamentoApp({
            ref_figma: '3',
            name: 'CONSIGNADO_PORT_FLUXO',
            screen: 'Tipo de Convenio',
            ref_type: 'Botao',
            content_action: 'Toque',
            action_id: codigo === FontesPagamento.INSS ? 'INSS' : 'SIAPE',
            product: `PORTABILIDADE_CONSIGNADO_${
              codigo === FontesPagamento.INSS ? 'INSS' : 'SIAPE'
            }`,
          })
          if (codigo === FontesPagamento.INSS) {
            setShowBottomSheetInss(true)
          } else if (codigo === FontesPagamento.SIAPE) {
            navigate(routes.CONSULTA_MARGEM_SIAPE_V2)
          } else {
            navigate(routes.ONBOARDING)
          }
        },
      }),
    )
  }

  if (loading) {
    return (
      <NovoPage id="page-grupos">
        <DivLoading>
          <Loading width={80} height={80} />
        </DivLoading>
      </NovoPage>
    )
  }

  return (
    <NovoPage id="selecao-convenio">
      <Text
        variant="headline-h1"
        color="typography"
        colorWeight={500}
        semiBold
        style={{
          margin: '22px 0',
        }}
      >
        Selecione o convênio que está seu contrato
      </Text>
      <>
        {homeConvenios.map(({ codigo, descricao, detalhes }) => (
          <LineInfo key={codigo} onClick={() => navigateNextPage(codigo)}>
            <ColumnText>
              <Text
                variant="body-3"
                color="typography"
                colorWeight={500}
                bold
                style={{
                  margin: '0',
                }}
              >
                {descricao}
              </Text>
              {detalhes ? <Text variant="body-3">{detalhes}</Text> : <></>}
            </ColumnText>
            {conveniosLoading && codigo === fonteSelecionada ? (
              <Loading />
            ) : (
              <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
            )}
          </LineInfo>
        ))}
      </>
      {showBottomSheetInss ? (
        <BottomsheetInss setShowBottomSheetInss={setShowBottomSheetInss} />
      ) : (
        <></>
      )}
    </NovoPage>
  )
}
export default SelecaoConvenio
