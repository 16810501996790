import React from 'react'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import { StepPortabilidadeNovo, TipoDocumento } from '@utils/enums'
import { Text } from '@interco/inter-ui/components/Text'
import { Colors } from '@utils/colors'
import { trackingRequest } from '@store/portabilidade/simulacao/actions'
import routes from '@routes/routes'
import { setTipoDocumento } from '@store/portabilidade/documentos/actions'
import { Tag } from '@interco/inter-ui/components/Tag'
import { BridgeService } from '@services/bridge'
import Tags from '@utils/Tags'

import { Bolder, ColumnText, LineInfo } from '../../styles'

interface Props {
  setOpenTipoDoc: React.Dispatch<React.SetStateAction<boolean>>
}
const BottomsheetTipoDoc = ({ setOpenTipoDoc }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectNavigate = (tipoDoc: string) => {
    dispatch(trackingRequest(StepPortabilidadeNovo.INICIO_CAPTURA_DOCUMENTOS))
    dispatch(setTipoDocumento(tipoDoc))
    BridgeService.tic({
      name: 'btn_modo_envio',
      action: 'solicitar_documento_id',
    })
    Tags.enviarTagueamentoWeb({
      element_name: tipoDoc,
      section_name: 'Modo envio',
      element_action: 'click button',
      c_page: window.location.href,
      redirect_url: `${window.location.origin}${routes.MODO_ENVIO_V2}`,
      step: 'null',
    })
    navigate(routes.MODO_ENVIO_V2)
  }

  return (
    <BottomSheet onClose={() => setOpenTipoDoc(false)}>
      <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
        Qual documento você quer enviar?
      </Text>
      <div style={{ marginBottom: '10px' }} />
      <Tag colorText={`${Colors.WHITE}!important`} color={Colors.PRIMARY500}>
        Frente e verso
      </Tag>
      <LineInfo onClick={() => selectNavigate(TipoDocumento.CNH)}>
        <ColumnText>
          <Text variant="body-3" color="typography" colorWeight={500}>
            <Bolder>Carteira de motorista (CNH)</Bolder>
          </Text>
        </ColumnText>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </LineInfo>
      <LineInfo onClick={() => selectNavigate(TipoDocumento.RG)}>
        <ColumnText>
          <Text variant="body-3" color="typography" colorWeight={500}>
            <Bolder>RG</Bolder>
          </Text>
        </ColumnText>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </LineInfo>
      <LineInfo onClick={() => selectNavigate(TipoDocumento.CARTEIRA_FUNCIONAL)} removeBorder>
        <ColumnText>
          <Text variant="body-3" color="typography" colorWeight={500}>
            <Bolder>Carteira funcional</Bolder>
          </Text>
        </ColumnText>
        <ChevronRight height={24} width={24} color={Colors.PRIMARY500} />
      </LineInfo>
    </BottomSheet>
  )
}
export default BottomsheetTipoDoc
