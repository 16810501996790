import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
  variant: {
    title: string
    text: string
    button: string
  }
}
export const BottomSheetError = ({ setShowBottomSheet, variant }: Props) => (
  <BottomSheet onClose={() => setShowBottomSheet(false)}>
    <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
      {variant.title}
    </Text>
    <br />
    <Text
      variant="body-3"
      color="typography"
      colorWeight={500}
      style={{
        margin: '0 0 16px 0',
      }}
    >
      {variant.text}
    </Text>
    <Button fullWidth onClick={() => setShowBottomSheet(false)} style={{ marginTop: '14px' }}>
      {variant.button}
    </Button>
  </BottomSheet>
)
